$( document ).ready(function (e) {

    var swiper = new Swiper('.main-banner-swiper-container', {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      navigation: {
       nextEl: '.opinions-slider-button-prev',
       prevEl: '.opinions-slider-button-next',
      }
    });

});
