$( document ).ready(function (e) {

    var swiper = new Swiper($(this).find('.realization-slider-swiper-container'), {
      slidesPerView: 1,
      spaceBetween: 5,
      loop: true,
      navigation: {
       nextEl: '.realization-slider-button-prev',
       prevEl: '.realization-slider-button-next',
      },
        breakpoints: {
          1200: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          760: {
            slidesPerView: 2,
            spaceBetween: 20,
          }
        }
    });
});
