var faqAccordionLink = document.getElementsByClassName("faq-item-btn");
var faqAccordionLinkI;

for (faqAccordionLinkI = 0; faqAccordionLinkI < faqAccordionLink.length; faqAccordionLinkI++) {
  faqAccordionLink[faqAccordionLinkI].addEventListener("click", function(e) {

    var panel = this.closest(".faq-item");
    if(panel.classList.contains("open")) {
      panel.classList.remove("open");
    } else {
      panel.classList.add("open");
    }

    e.preventDefault();

  });
}
