var inputs = document.getElementsByClassName("form-file-input");
if(inputs.length > 0) {
  inputs[0].onchange = function () {

      let fileName = this.files[0].name;

      if (this.files.length > 1) {
          fileName = "załączono " + this.files.length + " pliki";
      }

      if (this.files.length > 4) {
          alert ("Możesz przesłać 4 pliki.")
          return;
      }

    document.getElementsByClassName("form-file-label")[0].innerHTML = fileName;
  };
}
