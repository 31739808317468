var realizationAccordionLink = document.getElementsByClassName("realization-item-more-link");
var realizationAccordionLinkI;

for (realizationAccordionLinkI = 0; realizationAccordionLinkI < realizationAccordionLink.length; realizationAccordionLinkI++) {
  realizationAccordionLink[realizationAccordionLinkI].addEventListener("click", function(e) {

    if(window.innerWidth < 1200){
      var panel = this.closest(".realization-item");
      if(panel.classList.contains("open")) {
        panel.classList.remove("open");
        this.innerHTML = "Rozwiń";
      } else {
        panel.classList.add("open");
        this.innerHTML = "Zwiń";
      }

      e.preventDefault();
    }
  });
}


var realizationAccordion = document.getElementsByClassName("realization-item-header");
var realizationAccordionI;

for (realizationAccordionI = 0; realizationAccordionI < realizationAccordion.length; realizationAccordionI++) {
  realizationAccordion[realizationAccordionI].addEventListener("click", function(e) {

    if(window.innerWidth >= 1200){
      var items = document.getElementsByClassName("realization-item");
      for (itemsI = 0; itemsI < items.length; itemsI++) {
        items[itemsI].classList.remove("open");
      }

      var panel = this.closest(".realization-item");
      panel.classList.add("open");
    }

    e.preventDefault();
  });
}

