function startBaseHelper(context){

  context = context || 'body';

  /*******************************************************
   ***************************************** target blank
   *******************************************************/
  $('a.external', context).attr('target', '_blank');

  $('.article-card-body', context).matchHeight({
    byRow: true
  });

}


$( document ).ready(function (e) {
  startBaseHelper();

  /*******************************************************
  ***************************************** page hader
  *******************************************************/
   $(document).on('click', '[data-toggle="navbar"]', function(e){
     if($('body').hasClass('open-navbar')) {
       $(this).addClass('collapsed');
       $('.page-header-navbar').removeClass('in');
       $('body').removeClass('open-navbar');
     } else {
       $(this).removeClass('collapsed');
       $('.page-header-navbar').addClass('in');
       $('body').addClass('open-navbar');
     }
     e.preventDefault();
   });
  var bLazy = new Blazy({offset: 50, selector: ".b-lazy"});

  var nextProjectPage = 3;
  $('.load-more-realizations').click(function (event) {
    event.preventDefault();

    $.ajax({
      url: "/project_getter?page="+nextProjectPage,
    beforeSend: function( xhr ) {
      $( this ).addClass( "loading" );
    }}).done(function(result) {
      nextProjectPage += 1;
      $( this ).removeClass( "loading" );

      if(result) {
        $( '#projects-container' ).append( result );
        startBaseHelper('#projects-container');
      } else {
        $('.footer-load').hide();
        $('.footer-more').show();
      }
    });
  })

  let href = window.location.href;
  let highlight = href.split('#')[1];
    $('#' + highlight).addClass('highlighted-box');
});
